import { GetTriageringsoppsettResponse } from '../../types/Helsenorge.Dialog.InternalEntitiesEntities';

import { ProxyErrorResponse } from '@helsenorge/framework-utils/hn-proxy-service';

import { LOAD, LOAD_SUCCESS, LOAD_FAIL, LoadAction, LoadSuccessAction, LoadFailedAction } from './actions';

export function loadTriageringsoppsett(): LoadAction {
  return {
    type: LOAD,
  };
}

export function loadTriageringsoppsettSuccess(response: GetTriageringsoppsettResponse): LoadSuccessAction {
  return {
    type: LOAD_SUCCESS,
    response: response,
  };
}

export function loadTriageringsoppsettFailed(response: ProxyErrorResponse): LoadFailedAction {
  return {
    type: LOAD_FAIL,
    error: {
      Title: response.message ? response.message : 'Det har skjedd en teknisk feil. Prøv igjen senere.',
      Body: '',
    },
  };
}
