import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { GetGeneralPractitionerOperationResponse } from '../../types/entities';

import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import { get as getProxy } from '@helsenorge/framework-utils/hn-proxy-service';
import { OperationResponse } from '@helsenorge/framework-utils/types/entities';

import { requestGeneralPractitioner, receiveGeneralPractitioner, failureGeneralPractitioner } from './action-creators';
import { GeneralPractitionerState, PartialState } from './state';

function shouldFetchGeneralPractitioner(state: GeneralPractitionerState): boolean {
  return !state.response && !state.isFetching && !state.error;
}

export function fetchGeneralPractitioner(): (
  dispatch: ThunkDispatch<PartialState, void, Action>,
  getState: () => PartialState
) => Promise<void> | null {
  return (dispatch: ThunkDispatch<PartialState, void, Action>, getState: () => PartialState): Promise<void> | null => {
    if (isAuthorized() && shouldFetchGeneralPractitioner(getState().generalpractitioner)) {
      dispatch(requestGeneralPractitioner());

      return getProxy('todlegacy', 'api/v1/MinHelse/GetGeneralPractitioner')
        .then((data: Response | null | object) => {
          dispatch(receiveGeneralPractitioner(data as GetGeneralPractitionerOperationResponse));
        })
        .catch((data: OperationResponse) => {
          dispatch(failureGeneralPractitioner(data));
        });
    }

    return null;
  };
}
