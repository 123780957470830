export const getAdresse = (adresse?: string | null, postnummer?: string | null, poststed?: string | null): string => {
  let retVal = '';
  if (adresse) {
    retVal += adresse;
  }
  if (postnummer || poststed) {
    retVal += ',';

    if (postnummer) {
      retVal += ` ${postnummer}`;
    }

    if (poststed) {
      retVal += ` ${poststed}`;
    }
  }
  return retVal;
};
