import { GetGeneralPractitionerOperationResponse } from '../../types/entities';

import { OperationResponse } from '@helsenorge/framework-utils/types/entities';

import {
  REQUEST_GENERAL_PRACTITIONER,
  RECEIVE_GENERAL_PRACTITIONER,
  FAILURE_GENERAL_PRACTITIONER,
  FailureGeneralPractitionerAction,
  RequestGeneralPractitionerAction,
  ReceiveGeneralPractitionerAction,
} from './actions';

export function requestGeneralPractitioner(): RequestGeneralPractitionerAction {
  return {
    type: REQUEST_GENERAL_PRACTITIONER,
  };
}

export function receiveGeneralPractitioner(data: GetGeneralPractitionerOperationResponse): ReceiveGeneralPractitionerAction {
  return {
    type: RECEIVE_GENERAL_PRACTITIONER,
    response: data,
  };
}

export function failureGeneralPractitioner(data: OperationResponse): FailureGeneralPractitionerAction {
  return {
    type: FAILURE_GENERAL_PRACTITIONER,
    error: data.ErrorMessage || {
      Title: 'Det har skjedd en teknisk feil.',
      Body: 'Prøv igjen senere.',
    },
  };
}
