import registerWebComp from '@helsenorge/framework-utils/web-component/register';

import Fastlegebanner from './fastlegebanner';
import Fastlegepanel from './fastlegepanel';

async function enableMocking(): Promise<ServiceWorkerRegistration | void> {
  if (import.meta.env.MODE !== 'mocks') {
    return;
  }

  const { worker } = await import('./mocks/browser');

  return worker.start({
    onUnhandledRequest: 'bypass',
  });
}

enableMocking().then(() => {
  registerWebComp(Fastlegebanner, 'hn-webcomp-fastlegebanner', { events: false }, 'hn-webcomp-fastlegebanner-template');
  registerWebComp(Fastlegepanel, 'hn-webcomp-fastlegepanel', { events: false }, 'hn-webcomp-fastlegepanel-template');
});
