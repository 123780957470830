import { FC } from 'react';

import AnchorLink from '@helsenorge/designsystem-react/components/AnchorLink';

export interface Props {
  url: string;
  title: string;
  className?: string;
}

const HomepageLink: FC<Props> = ({ url, title, className }) => {
  const homepage = url.startsWith('http') ? url : 'http://' + url;
  return (
    <AnchorLink target={'_blank'} href={homepage} className={className}>
      {title}
    </AnchorLink>
  );
};

export default HomepageLink;
