import { GetGeneralPractitionerOperationResponse, PatientStatus } from '../types/entities';
import { GetTriageringsoppsettResponse } from '../types/Helsenorge.Dialog.InternalEntitiesEntities';
import { HNDialogMicrowebFastlegepanel } from '../types/Resources';

import { FravaerDatoer } from '@helsenorge/dialog-common/store/tidsperiode';

import { FASTLEGE_TJENESTER_PATH, FASTLEGEN_PATH, HELSEKONTAKTER_PATH } from '../constants/navigation-paths';
import { getHasFravaer, getHasFravaerResept, getHasServices, getTjenesteUrls } from '../utils/fastlege-utils';

export const getFastlegeFullName = (generalpractitionerResponse: GetGeneralPractitionerOperationResponse): string => {
  return `${generalpractitionerResponse.FirstName}${
    generalpractitionerResponse.MiddleName ? ` ${generalpractitionerResponse.MiddleName}` : ''
  } ${generalpractitionerResponse.LastName}`;
};

export interface Link {
  title: string;
  description: string;
  href: string;
}

/*
Gir fastlege-lenke basert på PatientStatus og om den har digitale tjenester. PatientStatus kan være følgende:
  LivingAbroad = Innbygger har utvandret (Kode "OHIU")
  NotInRegularGPScheme = Innbygger har meldt seg ut av fastlegeordningen (Kode "OHUT")
  NotAssignedDoctor = Har ikke fastlege (RegularGPGateway/RegularGPHistoryGateway feilet)
  NormalPatient = Har fastlege eller deleliste (Kode "LPFL" eller "LPDL")
  LocumOnly = Har vikar (Kode "LPVI")
  VacantList = Står på liste uten fastlege (GP er null)
*/
export const getFastlegeLink = (
  resources: HNDialogMicrowebFastlegepanel,
  generalpractitionerResponse: GetGeneralPractitionerOperationResponse,
  fravaerDatoer?: FravaerDatoer
): Link => {
  if (
    generalpractitionerResponse.PatientStatus === PatientStatus.NotAssignedDoctor ||
    generalpractitionerResponse.PatientStatus === PatientStatus.NotInRegularGPScheme ||
    generalpractitionerResponse.PatientStatus === PatientStatus.LivingAbroad
  ) {
    return {
      title: resources.fastlegepanelLinkTitleIngenFastlege,
      description: resources.fastlegepanelLinkDescriptionIngenFastlege,
      href: FASTLEGEN_PATH,
    };
  } else if (getHasFravaer(fravaerDatoer) || (generalpractitionerResponse.IsDigitalActive && getHasServices(generalpractitionerResponse))) {
    return {
      title: resources.fastlegepanelLinkTitleFastlegetjenester,
      description: resources.fastlegepanelLinkDescriptionFastlegetjenester,
      href: FASTLEGE_TJENESTER_PATH,
    };
  } else {
    return {
      title: resources.fastlegepanelLinkTitleFastlegen,
      description: resources.fastlegepanelLinkDescriptionFastlegen,
      href: FASTLEGEN_PATH,
    };
  }
};

export const getHelsekontakterLink = (resources: HNDialogMicrowebFastlegepanel, harHelsekontakterTilgang: boolean): Link | undefined => {
  return harHelsekontakterTilgang
    ? {
        title: resources.fastlegepanelLinkTitleHelsekontakter,
        description: resources.fastlegepanelLinkDescriptionHelsekontakter,
        href: HELSEKONTAKTER_PATH,
      }
    : undefined;
};

export const getUrlBestillTime = (harTimeavtalerTilgang: boolean): string | undefined => {
  if (harTimeavtalerTilgang) {
    return '/timeavtaler/bestill-time-velger?redirectTo=forside';
  }
};

export const getUrlSkrivMelding = (harMeldingerTilgang: boolean): string | undefined => {
  if (harMeldingerTilgang) {
    return '/meldinger/skriv-melding';
  }
};

export const getUrlFornyResept = (
  generalpractitionerResponse?: GetGeneralPractitionerOperationResponse,
  triageringsoppsettResponse?: GetTriageringsoppsettResponse,
  fravaerDatoer?: FravaerDatoer
): string | undefined => {
  if (
    generalpractitionerResponse &&
    generalpractitionerResponse.IsDigitalActive &&
    (generalpractitionerResponse.NewPrescriptionHash || generalpractitionerResponse.NewStructuredPrescriptionHash) &&
    !getHasFravaerResept(fravaerDatoer)
  ) {
    return getTjenesteUrls(triageringsoppsettResponse?.verktoy || []).fornyResept;
  }

  return getTjenesteUrls([]).fornyResept;
};
