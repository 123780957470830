import { GetGeneralPractitionerOperationResponse } from '../../types/entities';

import { TextMessage } from '@helsenorge/framework-utils/types/entities';

export interface GeneralPractitionerState {
  isFetching: boolean;
  response?: GetGeneralPractitionerOperationResponse;
  error?: TextMessage;
}

export interface PartialState {
  generalpractitioner: GeneralPractitionerState;
}

export const initialState: GeneralPractitionerState = {
  isFetching: false,
};
