
/* tslint:disable */
// prettier-ignore
// ReSharper disable RedundantEmptyConstructor
// ReSharper disable InconsistentNaming
/* 
 * This file is automatically generated by Visual Studio, all manual changes to this file will be lost.
 *
 * To regenerate this file make sure you
 *		1: Build the EntitiesGenerator.Console project
 *		2: Either run the project directly in Visual Studio, or execute EntitiesGenerator.Console.dll in the folder <Repo>\Source\EntitiesGenerator.Console\bin\Release\netcoreapp2.2
 *
 * This file is generated from classes inheriting from the BaseRequest or BaseResponse classes in the EHelse.Common.Api.Abstractions.Entities project. 
 * All properties, excluding the JsonIgnore attribute, in these or base classes is included. Properties which could not be properly
 * resolved defaults to TypeScripts 'any' type.
 *
 * Properties of IList, IEnumerable or ICollection(etc) is excluded(use a actual implementation to generate the type), some types are automatically 
 * excluded since they should not be available in JavaScript(e.g.UserContext).
 */
// ------ Enum members ------
export enum Dialogtjeneste
{
  fastlegekonsultasjon = 1,
  eKonsultasjon = 2,
  reseptfornyelse = 3,
  timebestillingKonsultasjon = 4,
  eKontakt = 6,
  timebestillingKoronatest = 7,
  timebestillingKoronavaksine = 8,
  timebestillingInfluensavaksine = 9,
  timebestillingLabtime = 10
}
export enum EksternLenkeSortering
{
  virksomhetNavn = 1,
  virksomhetHerId = 2,
  virksomhetOrgNr = 3,
  kommunikasjonspartHerId = 4,
  leverandorNavn = 5,
  datoFra = 6,
  datoTil = 7,
  revokert = 8,
  dropIn = 9
}
export enum EksternLenkeType
{
  ukjent = 0,
  video = 1,
  innbyggerverktoy = 2,
  informasjonsmateriell = 3,
  videourlForValidering = 4
}
export enum Feilsituasjoner
{
  ingenFeil = 0,
  harIkkeParametere = 1,
  skjemaEksistererIkke = 2
}
export enum FunksjonellMotpartCapabilities
{
  none = 0,
  changeAppointment = 1,
  newOnlineAppointment = 2,
  newOfflineAppointment = 4,
  newPrescription = 8,
  newUnstructuredPrescription = 16,
  newConsultation = 32,
  newPrimarhelsetjenesteAppointment = 64
}
export enum FunksjonellMotpartType
{
  ukjent = 0,
  person = 1,
  tjeneste = 2,
  organisasjon = 3,
  ressurstimebok = 4
}
export enum FysiskOppmote
{
  ukjent = 0,
  ja = 1,
  nei = 2
}
export enum Haster
{
  ukjent = 0,
  akutt = 1,
  ja = 2,
  nei = 3
}
export enum IdentifierType
{
  herId = 0,
  hprId = 1,
  orgId = 2,
  other = 3,
  personalId = 4
}
export enum KravTilInternScope
{
  omradeHelseregister = 1,
  omradePasientjournal = 2,
  omradeHelsehjelp = 3,
  omradeHelseforvaltning = 4,
  omradeSekunderbruk = 5
}
export enum MessageAction
{
  save = 0,
  send = 1
}
export enum RessurstimeType
{
  ukjent = 0,
  koronavaksine = 1,
  koronatest = 2,
  lab = 3,
  vaksine = 4
}
export enum RoleToPatientType
{
  ukjent = 0,
  behandlingsansvarligLege = 3,
  journalansvarlig = 4,
  informasjonsansvarlig = 5,
  fastlege = 6,
  fagligAnsvarligPsykiskHelsevern = 7,
  koordinatorIndividuellPlan = 8,
  primarkontakt = 9,
  utskrivendeLege = 10,
  utskrivendeSykepleier = 11,
  instituerendeLege = 12,
  innleggendeLege = 13,
  ansvarligJordmor = 14,
  vikarForFastlege = 15,
  turnuslege = 16,
  forlopskoordinatorKreft = 17,
  koordinatorSpesialisthelsetjenesteLoven = 18,
  kontaktlege = 19,
  kontaktpsykolog = 20,
  helsefagligKontakt = 21,
  kontaktIPrimarhelseteam = 22,
  onsketBehandler = 23,
  kontaktHosMottaker = 24,
  tannhelsesekretaer = 101,
  tannlege = 102,
  tannpleier = 103,
  tanntekniker = 104
}
export enum Smittefare
{
  ukjent = 0,
  ja = 1,
  nei = 2
}
export enum StatusType
{
  underArbeid = 1,
  publisert = 2,
  erstattetAvNyereVersjon = 3,
  trukketTilbake = 4
}
export enum VerktoyFormal
{
  informasjon = 1,
  laering = 2,
  veiledning = 3,
  behandling = 4,
  kartlegging = 5,
  sjekkpunkt = 6,
  kontrollsporsmal = 7
}
// ReSharper restore RedundantEmptyConstructor
// ReSharper restore InconsistentNaming
/* tslint:enable */