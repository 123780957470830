import { useState, useRef, FC } from 'react';

import { FastlegeServices } from '../types/entities';
import { HNDialogMicrowebFastlegepanel } from '../types/Resources';

import Calendar from '@helsenorge/designsystem-react/components/Icons/Calendar';
import MaleDoctor from '@helsenorge/designsystem-react/components/Icons/MaleDoctor';
import Medicine from '@helsenorge/designsystem-react/components/Icons/Medicine';
import Receptionist from '@helsenorge/designsystem-react/components/Icons/Receptionist';
import VideoChat from '@helsenorge/designsystem-react/components/Icons/VideoChat';

import TjenesteLink from './tjeneste-link';
import { formatNumber, getIndividueltTriageringsoppsettHref, getVelgSamtykkeUrl, trimNumber } from './utils';
import KontaktLegekontoret from '../kontakt-legekontoret';
import { trackEkonsultasjonIntent, trackKontaktLegekontoretIntent } from '../utils/adobe-analytics-utils';
import { getTjenesteUrls } from '../utils/fastlege-utils';
import VideoDialog from '../video-dialog';

import dialogstyles from './styles.module.scss';

interface Props {
  resources: HNDialogMicrowebFastlegepanel;
  services: FastlegeServices;
}

const FastlegeTjenester: FC<Props> = ({ services, resources }) => {
  const videoDialogButtonRef = useRef<HTMLButtonElement>(null);
  const kontaktLegekontoretButtonRef = useRef<HTMLButtonElement>(null);

  const velgSamtykkeUrl = getVelgSamtykkeUrl();
  const tjenesteUrls = getTjenesteUrls(services.verktoy);
  const [visKontaktLegekontoret, setVisKontaktLegekontoret] = useState(false);
  const [visVideoDialog, setVisVideoDialog] = useState(false);

  const onOpenKontaktLegekontoret = (): void => {
    setVisKontaktLegekontoret(true);
    trackKontaktLegekontoretIntent();
  };

  const onCloseKontaktLegekontoret = (): void => {
    setVisKontaktLegekontoret(false);

    if (kontaktLegekontoretButtonRef.current) {
      kontaktLegekontoretButtonRef.current.focus();
    }
  };

  const onOpenVideoDialog = (): void => {
    setVisVideoDialog(true);
  };

  const onCloseVideoDialog = (): void => {
    setVisVideoDialog(false);

    if (videoDialogButtonRef.current) {
      videoDialogButtonRef.current.focus();
    }
  };

  let individueltTriageringsoppsett;
  let bestillTime;
  let eKonsultasjon;
  let kontaktLegekontoret;
  let fornyResept;
  let videoKonsultasjon;
  let telefon;

  if (services.isDigitalActive) {
    if (services.individueltTriageringsoppsett && tjenesteUrls.kartlegging) {
      const individueltTriageringsoppsettHref = getIndividueltTriageringsoppsettHref(services.serviceAccess, tjenesteUrls.kartlegging);

      individueltTriageringsoppsett = (
        <TjenesteLink
          className={dialogstyles['fastlege-tjenester__button']}
          href={individueltTriageringsoppsettHref}
          icon={MaleDoctor}
          text={resources.fastlegepanelFastlegekonsultasjon}
          fill
        />
      );
    } else {
      if (services.showOnlineAppointment || services.showOfflineAppointment) {
        bestillTime = (
          <TjenesteLink
            className={dialogstyles['fastlege-tjenester__button']}
            href={services.serviceAccess ? tjenesteUrls.bestillTime : velgSamtykkeUrl}
            icon={Calendar}
            text={resources.fastlegepanelBestillTime}
          />
        );
      }

      if (services.showConsultation) {
        eKonsultasjon = (
          <TjenesteLink
            className={dialogstyles['fastlege-tjenester__button']}
            href={services.serviceAccess ? tjenesteUrls.eKonsultasjon : velgSamtykkeUrl}
            icon={MaleDoctor}
            text={resources.fastlegepanelEkonsultasjon}
            onClick={trackEkonsultasjonIntent}
          />
        );
      }
    }

    if (services.showPrescription || services.showStructuredPrescription) {
      fornyResept = (
        <TjenesteLink
          className={dialogstyles['fastlege-tjenester__button']}
          href={services.serviceAccess ? tjenesteUrls.fornyResept : velgSamtykkeUrl}
          icon={Medicine}
          text={resources.fastlegepanelFornyResept}
        />
      );
    }

    if (services.showContact) {
      kontaktLegekontoret = (
        <TjenesteLink
          className={dialogstyles['fastlege-tjenester__button']}
          href={!services.serviceAccess ? velgSamtykkeUrl : undefined}
          onClick={services.serviceAccess ? onOpenKontaktLegekontoret : undefined}
          icon={Receptionist}
          text={resources.fastlegepanelKontaktLegekontoret}
          ref={kontaktLegekontoretButtonRef}
        />
      );
    }
  } else if (services.practitionerPhoneNumber) {
    const formatedNumber = trimNumber(formatNumber(services.practitionerPhoneNumber));

    telefon = (
      <TjenesteLink
        className={dialogstyles['fastlege-tjenester__button']}
        text={services.practitionerPhoneNumber}
        icon={Receptionist}
        href={`tel:${formatedNumber}`}
      />
    );
  }

  if (!!services.videoConsultationUrl && !!services.tilbyrDropin) {
    videoKonsultasjon = (
      <TjenesteLink
        className={dialogstyles['fastlege-tjenester__button']}
        onClick={onOpenVideoDialog}
        icon={VideoChat}
        text={resources.fastlegepanelVideokonsultasjon}
        ref={videoDialogButtonRef}
      />
    );
  }

  const visKnapper =
    !!individueltTriageringsoppsett ||
    !!bestillTime ||
    !!fornyResept ||
    !!eKonsultasjon ||
    !!kontaktLegekontoret ||
    !!telefon ||
    !!videoKonsultasjon;

  return (
    <>
      {visKnapper && (
        <nav className={dialogstyles['fastlege-tjenester__buttons']}>
          {individueltTriageringsoppsett}
          {bestillTime}
          {fornyResept}
          {eKonsultasjon}
          {kontaktLegekontoret}
          {telefon}
          {videoKonsultasjon}
        </nav>
      )}

      {visVideoDialog && services.videoConsultationUrl && (
        <VideoDialog
          resources={resources}
          videoUrl={services.videoConsultationUrl}
          produktveiledningUrl={services.produktveiledningUrl}
          onClose={onCloseVideoDialog}
        />
      )}

      {visKontaktLegekontoret && <KontaktLegekontoret resources={resources} services={services} onClose={onCloseKontaktLegekontoret} />}
    </>
  );
};

export default FastlegeTjenester;
