import { Action, ThunkDispatch } from '@reduxjs/toolkit';

import { GetTriageringsoppsettRequest, GetTriageringsoppsettResponse } from '../../types/Helsenorge.Dialog.InternalEntitiesEntities';

import { get, ProxyErrorResponse } from '@helsenorge/framework-utils/hn-proxy-service';

import { loadTriageringsoppsett, loadTriageringsoppsettSuccess, loadTriageringsoppsettFailed } from './action-creators';
import { getTriageringsoppsettLoading, getTriageringsoppsettResponse } from './selectors';
import { PartialState } from './state';

function shouldFetchTriageringsoppsett(state: PartialState): boolean {
  return !getTriageringsoppsettLoading(state) && !getTriageringsoppsettResponse(state);
}

export function fetchTriageringsoppsett(
  herId: number
): (dispatch: ThunkDispatch<PartialState, void, Action>, getState: () => PartialState) => Promise<void> | null {
  return (dispatch: ThunkDispatch<PartialState, void, Action>, getState: () => PartialState) => {
    if (shouldFetchTriageringsoppsett(getState())) {
      dispatch(loadTriageringsoppsett());

      return get<GetTriageringsoppsettResponse>('dialog', 'api/v1/Triageringsoppsett', {
        herId: herId,
      } as Record<keyof GetTriageringsoppsettRequest, number>)
        .then((response: Response | null | object) => {
          dispatch(loadTriageringsoppsettSuccess(response as GetTriageringsoppsettResponse));
        })
        .catch((response: ProxyErrorResponse) => {
          dispatch(loadTriageringsoppsettFailed(response));
        });
    }

    return null;
  };
}
