import { GetGeneralPractitionerOperationResponse, FastlegeServices, TjenesteUrls } from '../types/entities';
import { GetTriageringsoppsettResponse, Triageringsverktoy } from '../types/Helsenorge.Dialog.InternalEntitiesEntities';
import { Dialogtjeneste, VerktoyFormal } from '../types/Helsenorge.Dialog.InternalEntitiesEnums';

import { FravaerDatoer } from '@helsenorge/dialog-common/store/tidsperiode';

export const getHasServices = (response: GetGeneralPractitionerOperationResponse): boolean => {
  return (
    !!response.NewConsultationHash ||
    !!response.NewContactHash ||
    !!response.NewJournalAccessHash ||
    !!response.NewOfflineAppointmentHash ||
    !!response.NewOnlineAppointmentHash ||
    !!response.NewPrescriptionHash ||
    !!response.NewStructuredPrescriptionHash ||
    !!response.VideoUrl
  );
};

export const getHasFravaer = (fravaerDatoer?: FravaerDatoer): boolean => {
  return !!fravaerDatoer?.ekonsultasjon || !!fravaerDatoer?.ekontakt || !!fravaerDatoer?.reseptfornyelse || !!fravaerDatoer?.timebestilling;
};

export const getHasFravaerResept = (fravaerDatoer?: FravaerDatoer): boolean => {
  return !!fravaerDatoer?.reseptfornyelse;
};

export const mapServices = (
  generalpractitionerResponse: GetGeneralPractitionerOperationResponse,
  triageringsoppsettResponse?: GetTriageringsoppsettResponse,
  noPhoneNumber?: boolean
): FastlegeServices => {
  return {
    isDigitalActive: generalpractitionerResponse.IsDigitalActive,
    serviceAccess: generalpractitionerResponse.ServiceAccess,
    showConsultation: !!generalpractitionerResponse.NewConsultationHash,
    showContact: !!generalpractitionerResponse.NewContactHash || !!generalpractitionerResponse.NewJournalAccessHash,
    showOfflineAppointment: !!generalpractitionerResponse.NewOfflineAppointmentHash,
    showOnlineAppointment: !!generalpractitionerResponse.NewOnlineAppointmentHash,
    showPrescription: !!generalpractitionerResponse.NewPrescriptionHash,
    showStructuredPrescription: !!generalpractitionerResponse.NewStructuredPrescriptionHash,
    videoConsultationUrl: generalpractitionerResponse.VideoUrl,
    produktveiledningUrl: generalpractitionerResponse.ProduktveiledningUrl,
    tilbyrDropin: generalpractitionerResponse.TilbyrDropin,
    practitionerPhoneNumber:
      !noPhoneNumber && generalpractitionerResponse.Addresses && generalpractitionerResponse.Addresses.length > 0
        ? generalpractitionerResponse.Addresses[0].PhoneNumber
        : undefined,
    timerTjenester: generalpractitionerResponse.TimerTjenester,
    individueltTriageringsoppsett:
      !!triageringsoppsettResponse &&
      triageringsoppsettResponse.individueltTriageringsoppsett &&
      (!!generalpractitionerResponse.NewOnlineAppointmentHash ||
        !!generalpractitionerResponse.NewOfflineAppointmentHash ||
        !!generalpractitionerResponse.NewConsultationHash),
    verktoy: triageringsoppsettResponse ? triageringsoppsettResponse.verktoy : [],
  };
};

export interface TjenesteVerktoy {
  skjemaUrl: string;
  kontrollsporsmalIngenValgt?: boolean;
  kontrollsporsmalIndividueltUrl?: string | null;
  kontrollsporsmalStandardUrl?: string | null;
  sjekkpunktIngenValgt?: boolean;
  sjekkpunktIndividueltUrl?: string | null;
  sjekkpunktStandardUrl?: string | null;
}

export const createTjenesteUrl = (tjenesteVerktoy: TjenesteVerktoy): string => {
  const {
    skjemaUrl,
    kontrollsporsmalIngenValgt,
    kontrollsporsmalIndividueltUrl,
    kontrollsporsmalStandardUrl,
    sjekkpunktIngenValgt,
    sjekkpunktIndividueltUrl,
    sjekkpunktStandardUrl,
  } = tjenesteVerktoy;

  const kontrollsporsmalUrl = kontrollsporsmalIngenValgt ? undefined : kontrollsporsmalIndividueltUrl || kontrollsporsmalStandardUrl;
  const sjekkpunktUrl = sjekkpunktIngenValgt ? undefined : sjekkpunktIndividueltUrl || sjekkpunktStandardUrl;

  if (kontrollsporsmalUrl && sjekkpunktUrl) {
    return `${kontrollsporsmalUrl}?redirect=${sjekkpunktUrl}`;
  } else if (sjekkpunktUrl) {
    return sjekkpunktUrl;
  } else if (kontrollsporsmalUrl) {
    return kontrollsporsmalUrl;
  }

  return skjemaUrl;
};

export const mapTjenesteVerktoy = (verktoy: Triageringsverktoy, tjenesteVerktoy: TjenesteVerktoy): TjenesteVerktoy => {
  const { url, formaal, kreverIndividueltOppsett, ingenValgt } = verktoy;

  if (formaal === VerktoyFormal.kontrollsporsmal) {
    if (ingenValgt) {
      tjenesteVerktoy.kontrollsporsmalIngenValgt = ingenValgt;
    }

    if (kreverIndividueltOppsett) {
      tjenesteVerktoy.kontrollsporsmalIndividueltUrl = url;
    } else {
      tjenesteVerktoy.kontrollsporsmalStandardUrl = url;
    }
  } else if (formaal === VerktoyFormal.sjekkpunkt) {
    if (ingenValgt) {
      tjenesteVerktoy.sjekkpunktIngenValgt = ingenValgt;
    }

    if (kreverIndividueltOppsett) {
      tjenesteVerktoy.sjekkpunktIndividueltUrl = url;
    } else {
      tjenesteVerktoy.sjekkpunktStandardUrl = url;
    }
  }

  return tjenesteVerktoy;
};

export const getTjenesteUrls = (verktoyListe: Array<Triageringsverktoy> | null): TjenesteUrls => {
  let kartleggingUrl;
  let tjenesteVerktoyEKonsultasjon: TjenesteVerktoy = { skjemaUrl: `/meldinger/e-konsultasjon` };
  let tjenesteVerktoyEKontakt: TjenesteVerktoy = { skjemaUrl: `/meldinger/kontakt-legekontoret` };
  let tjenesteVerktoyFornyResept: TjenesteVerktoy = { skjemaUrl: `/meldinger/forny-resept` };

  if (verktoyListe) {
    verktoyListe.forEach((verktoy: Triageringsverktoy) => {
      const { dialogtjeneste, url, formaal } = verktoy;

      if (dialogtjeneste === Dialogtjeneste.fastlegekonsultasjon && formaal === VerktoyFormal.kartlegging) {
        kartleggingUrl = url;
      } else if (dialogtjeneste === Dialogtjeneste.eKonsultasjon) {
        tjenesteVerktoyEKonsultasjon = mapTjenesteVerktoy(verktoy, tjenesteVerktoyEKonsultasjon);
      } else if (dialogtjeneste === Dialogtjeneste.eKontakt) {
        tjenesteVerktoyEKontakt = mapTjenesteVerktoy(verktoy, tjenesteVerktoyEKontakt);
      } else if (dialogtjeneste === Dialogtjeneste.reseptfornyelse) {
        tjenesteVerktoyFornyResept = mapTjenesteVerktoy(verktoy, tjenesteVerktoyFornyResept);
      }
    });
  }

  return {
    kartlegging: kartleggingUrl,
    bestillTime: `/timeavtaler/bestill-time`,
    eKonsultasjon: createTjenesteUrl(tjenesteVerktoyEKonsultasjon),
    eKontakt: createTjenesteUrl(tjenesteVerktoyEKontakt),
    fornyResept: createTjenesteUrl(tjenesteVerktoyFornyResept),
  };
};
