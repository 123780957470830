import { FC } from 'react';

import { GetGeneralPractitionerOperationResponse } from '../types/entities';
import { HNDialogMicrowebFastlegepanel } from '../types/Resources';

import PromoPanel from '@helsenorge/designsystem-react/components/PromoPanel';

import Languages from '@helsenorge/core-utils/constants/languages';
import { FravaerDatoer } from '@helsenorge/dialog-common/store/tidsperiode';

import { getFastlegeLink, getHelsekontakterLink } from './utils';
import { HNDialogMicrowebFastlegepanelProject } from '../constants/project-name.constant';
import { getResourceItem } from '../resources';

import dialogstyles from './styles.module.scss';

interface Props {
  harHelsekontakterTilgang: boolean;
  generalpractitionerResponse: GetGeneralPractitionerOperationResponse;
  fravaerDatoer?: FravaerDatoer;
}

const FastlegepanelLinks: FC<Props> = ({ generalpractitionerResponse, harHelsekontakterTilgang, fravaerDatoer }) => {
  const resources: HNDialogMicrowebFastlegepanel = getResourceItem(
    HNDialogMicrowebFastlegepanelProject,
    Languages.NORWEGIAN
  ) as HNDialogMicrowebFastlegepanel;

  const fastlegeLink = getFastlegeLink(resources, generalpractitionerResponse, fravaerDatoer);
  const helsekontakterLink = getHelsekontakterLink(resources, harHelsekontakterTilgang);

  return (
    <div className="row">
      <div className="col">
        <div className={dialogstyles['fastlegepanel__links']}>
          <PromoPanel color="cherry" href={fastlegeLink.href} title={fastlegeLink.title} illustration="Doctor">
            {fastlegeLink.description}
          </PromoPanel>

          {helsekontakterLink && (
            <PromoPanel
              color="blueberry"
              href={helsekontakterLink.href}
              title={helsekontakterLink.title}
              illustration="HealthcarePersonnel"
            >
              {helsekontakterLink.description}
            </PromoPanel>
          )}
        </div>
      </div>
    </div>
  );
};

export default FastlegepanelLinks;
