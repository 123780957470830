import { FC } from 'react';

import { HNDialogMicrowebFastlegepanel } from '../types/Resources';

import AnchorLink from '@helsenorge/designsystem-react/components/AnchorLink';
import Button from '@helsenorge/designsystem-react/components/Button';
import Modal from '@helsenorge/designsystem-react/components/Modal';

import dialogstyles from '../styles.module.scss';

interface Props {
  dialogInfoMessage?: string;
  resources: HNDialogMicrowebFastlegepanel;
  videoUrl: string;
  produktveiledningUrl?: string;
  onClose: () => void;
}

const VideoDialog: FC<Props> = ({ resources, dialogInfoMessage, videoUrl, produktveiledningUrl, onClose }) => {
  return (
    <Modal title={resources.videoDialogTitle} onClose={onClose} size="large">
      <p>{dialogInfoMessage || resources.videoDialogDropinInfoMessage}</p>

      {produktveiledningUrl ? (
        <p>
          <AnchorLink href={produktveiledningUrl} target="_blank">
            {resources.videoDialogUserManualLinkText}
          </AnchorLink>
        </p>
      ) : null}

      <div className={dialogstyles['dialog__buttons-wrapper']}>
        <div className={dialogstyles['dialog__buttons-wrapper__button']}>
          <Button href={videoUrl} htmlMarkup="a" target="_blank" onClick={onClose}>
            {resources.videoDialogSubmitButton}
          </Button>
        </div>

        <div className={dialogstyles['dialog__buttons-wrapper__button']}>
          <Button variant="outline" onClick={onClose}>
            {resources.videoDialogCancelButton}
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default VideoDialog;
