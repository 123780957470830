import { HNDialogMicrowebFastlegepanel } from '../types/Resources';

import Languages from '@helsenorge/core-utils/constants/languages';
import { getModifiedResourcesForTest } from '@helsenorge/framework-utils/resources';

import defaultResources from './HN.Dialog.Microweb.Fastlegepanel.nb-NO.json';
import fastlegepanelResources from './HN.Dialog.Microweb.Fastlegepanel.nb-NO.json';
import { HNDialogMicrowebFastlegepanelProject } from '../constants/project-name.constant';

type ResourceItem = { [key: string]: string } | Record<string, string>;

export const getResourceItem = (projectName: string, language: Languages): ResourceItem | undefined => {
  if (projectName === HNDialogMicrowebFastlegepanelProject && language === Languages.NORWEGIAN) {
    return getModifiedResourcesForTest(fastlegepanelResources);
  }
};

export const getResourcesTestHelper = (): HNDialogMicrowebFastlegepanel => {
  return getModifiedResourcesForTest(defaultResources) as HNDialogMicrowebFastlegepanel;
};
