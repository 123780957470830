import { EmptyAction } from '@helsenorge/framework-utils/types/entities';

import { LOAD, LOAD_FAIL, LOAD_SUCCESS, TriageringsoppsettAction } from './actions';
import { TriageringsoppsettState, initialState } from './state';

export default function triageringsoppsett(
  state: TriageringsoppsettState = initialState,
  action: TriageringsoppsettAction = EmptyAction
): TriageringsoppsettState {
  switch (action.type) {
    case LOAD:
      return {
        ...state,
        loading: true,
      };
    case LOAD_SUCCESS:
      return {
        ...state,
        loading: false,
        response: action.response,
      };
    case LOAD_FAIL:
      return {
        ...state,
        loading: false,
        error: action.error,
      };
    default:
      return state;
  }
}
