import { FC } from 'react';

import { GetGeneralPractitionerOperationResponse } from '../../types/entities';
import { HNDialogMicrowebFastlegepanel } from '../../types/Resources';

import AnchorLink from '@helsenorge/designsystem-react/components/AnchorLink';
import Title from '@helsenorge/designsystem-react/components/Title';

import { getAdresse } from './utils';
import HomepageLink from '../homepage-link';
import dialogstyles from '../styles.module.scss';

interface Props {
  resources: HNDialogMicrowebFastlegepanel;
  generalpractitionerResponse: GetGeneralPractitionerOperationResponse;
  legekontornavn?: string | null;
  legekontoradresse?: string | null;
  legekontorpostnr?: string | null;
  legekontorpoststed?: string | null;
  legekontortelefon?: string | null;
  legekontorurl?: string | null;
}

const Contact: FC<Props> = ({
  resources,
  generalpractitionerResponse,
  legekontornavn,
  legekontoradresse,
  legekontorpostnr,
  legekontorpoststed,
  legekontortelefon,
  legekontorurl,
}) => {
  const adresse = getAdresse(legekontoradresse, legekontorpostnr, legekontorpoststed);

  return (
    <div className={dialogstyles['fastlegebanner__contact']}>
      {!!legekontornavn && (
        <Title htmlMarkup="h2" appearance="title4" className={dialogstyles['fastlegebanner__legekontorname']}>
          {legekontornavn}
        </Title>
      )}

      {!!adresse && <div>{adresse}</div>}

      {!!legekontortelefon && <AnchorLink href={`tel:${legekontortelefon.replace(/\s/g, '')}`}>{legekontortelefon}</AnchorLink>}

      {generalpractitionerResponse.IsDigitalActive && !!legekontorurl && (
        <div>
          <HomepageLink url={legekontorurl} title={resources.fastlegeBannerLegekontorLink} />
        </div>
      )}
    </div>
  );
};

export default Contact;
