import { FC } from 'react';

import { HNDialogMicrowebFastlegepanel } from '../../types/Resources';

import Spacer from '@helsenorge/designsystem-react/components/Spacer';

import HomepageLink from '../homepage-link';

interface Props {
  resources: HNDialogMicrowebFastlegepanel;
  legekontorurl?: string | null;
}

const NoServiceInfo: FC<Props> = ({ resources, legekontorurl }) => {
  return (
    <div>
      <Spacer size="s" />
      <span>{resources.fastlegeBannerNoServiceDescription}</span>

      {!!legekontorurl && (
        <>
          <Spacer size="2xs" />
          <HomepageLink url={legekontorurl} title={resources.fastlegeBannerLegekontorLink} />
        </>
      )}

      <Spacer size="s" />
    </div>
  );
};

export default NoServiceInfo;
