import { GetTriageringsoppsettResponse } from '../../types/Helsenorge.Dialog.InternalEntitiesEntities';

import { TextMessage } from '@helsenorge/framework-utils/types/entities';

import { PartialState } from './state';

export function getTriageringsoppsettLoading(state: PartialState): boolean {
  return state.triageringsoppsett.loading;
}

export function getTriageringsoppsettError(state: PartialState): TextMessage | undefined {
  return state.triageringsoppsett.error;
}

export function getTriageringsoppsettResponse(state: PartialState): GetTriageringsoppsettResponse | undefined {
  return state.triageringsoppsett.response;
}
