import { OperationResponse } from '@helsenorge/framework-utils/types/entities';

// ------ Constants ------

import { Triageringsverktoy } from './Helsenorge.Dialog.InternalEntitiesEntities';

export enum HttpStatusCode {
  Continue = 100,
  SwitchingProtocols = 101,
  OK = 200,
  Created = 201,
  Accepted = 202,
  NonAuthoritativeInformation = 203,
  NoContent = 204,
  ResetContent = 205,
  PartialContent = 206,
  MultipleChoices = 300,
  MovedPermanently = 301,
  Redirect = 302,
  SeeOther = 303,
  NotModified = 304,
  UseProxy = 305,
  Unused = 306,
  TemporaryRedirect = 307,
  BadRequest = 400,
  Unauthorized = 401,
  PaymentRequired = 402,
  Forbidden = 403,
  NotFound = 404,
  MethodNotAllowed = 405,
  NotAcceptable = 406,
  ProxyAuthenticationRequired = 407,
  RequestTimeout = 408,
  Conflict = 409,
  Gone = 410,
  LengthRequired = 411,
  PreconditionFailed = 412,
  RequestEntityTooLarge = 413,
  RequestUriTooLong = 414,
  UnsupportedMediaType = 415,
  RequestedRangeNotSatisfiable = 416,
  ExpectationFailed = 417,
  UpgradeRequired = 426,
  InternalServerError = 500,
  NotImplemented = 501,
  BadGateway = 502,
  ServiceUnavailable = 503,
  GatewayTimeout = 504,
  HttpVersionNotSupported = 505,
}

export interface Address {
  PostalCode: string;
  StreetAddressLine1: string;
  StreetAddressLine2: string;
  StreetAddressLine3: string;
  City: string;
  PhoneNumber: string;
  Postbox: string;
  Country: string;
  Municipality: string;
  MunicipalityNumber: string;
  PostalAddress: string;
  CoAddress: string;
  DateAddressFrom: string;
}

export enum PatientStatus {
  LivingAbroad = 0,
  NotInRegularGPScheme = 1,
  NotAssignedDoctor = 2,
  PersonNumberTerminated = 3,
  PatientIsDead = 4,
  ReturnParentsDoctor = 5,
  DNumberWithoutDoctor = 6,
  SamiSpeaker = 7,
  NormalPatient = 8,
  UnknownPatient = 9,
  UnknownStatus = 10,
  VacantList = 11,
  LocumOnly = 12,
}

export enum RepresentasjonforholdType {
  InnloggetBruker = 0,
  Fullmakt = 1,
  Foreldrerepresentasjon = 2,
  Saksbehandler = 3,
  SaksbehandlerFullmakt = 4,
}

export enum StatusKodeType {
  Reservert = 1,
  IkkeReservert = 2,
  Samtykket = 3,
  IkkeSamtykket = 4,
  TilgangsbegrensningOpprettet = 5,
  TilgangsbegrensningFjernet = 6,
  AnmodningTilbakeTrekkingSamtykke = 7,
}

export interface TimerTjeneste {
  HerId: number;
  NewPrimarhelsetjenesteAppointmentHash: string;
  HentBehandlereHash: string;
  TjenesteNavn: string;
}

export interface GetGeneralPractitionerOperationResponse extends OperationResponse {
  FirstName: string;
  LastName: string;
  MiddleName: string;
  BusinessName: string;
  ValidTo: string;
  ValidFrom: string;
  OrganisationNumber: number;
  HerId: number;
  ParentHerId: number;
  Message: string;
  MessageEContact: string;
  MessageEConsultation: string;
  MessageAppointment: string;
  MessagePrescription: string;
  OpeningHours: Array<{ From: string; To: string }>;
  OpeningHoursText: string;
  Homepage: string;
  PatientStatus: PatientStatus;
  IsDigitalActive: boolean;
  Addresses: Array<Address>;
  VideoUrl: string;
  ProduktveiledningUrl: string;
  TilbyrDropin: boolean;
  NewContactHash: string;
  NewConsultationHash: string;
  NewPrescriptionHash: string;
  NewStructuredPrescriptionHash: string;
  NewOnlineAppointmentHash: string;
  NewOfflineAppointmentHash: string;
  ServiceAccess: boolean;
  NewJournalAccessHash: string;
  TimerTjenester: Array<TimerTjeneste>;
}

export interface FastlegeServices {
  isDigitalActive: boolean;
  serviceAccess: boolean;
  showConsultation: boolean;
  showContact: boolean;
  showOfflineAppointment: boolean;
  showOnlineAppointment: boolean;
  showPrescription: boolean;
  showStructuredPrescription: boolean;
  videoConsultationUrl?: string;
  produktveiledningUrl?: string;
  tilbyrDropin: boolean;
  practitionerPhoneNumber?: string;
  timerTjenester?: TimerTjeneste[];
  individueltTriageringsoppsett: boolean;
  verktoy: Array<Triageringsverktoy> | null;
}

export interface TjenesteUrls {
  kartlegging?: string;
  bestillTime?: string;
  eKonsultasjon?: string;
  eKontakt?: string;
  fornyResept?: string;
}

export class FeatureNames {}
