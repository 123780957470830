import { PropsWithChildren, FC } from 'react';

import { GetGeneralPractitionerOperationResponse } from '../../types/entities';
import { HNDialogMicrowebFastlegepanel } from '../../types/Resources';

import AnchorLink from '@helsenorge/designsystem-react/components/AnchorLink';

import { getErRepresentasjon } from '@helsenorge/framework-utils/hn-user';

import NoServiceInfo from './no-service-info';
import { getHasServices } from '../../utils/fastlege-utils';

interface Props extends PropsWithChildren<unknown> {
  resources: HNDialogMicrowebFastlegepanel;
  legekontorurl?: string | null;
  generalpractitionerResponse: GetGeneralPractitionerOperationResponse;
}

const DigitaleTjenester: FC<Props> = ({ resources, generalpractitionerResponse, legekontorurl, children }) => {
  if (!getErRepresentasjon() && !getHasServices(generalpractitionerResponse)) {
    return <>{!generalpractitionerResponse.IsDigitalActive && <NoServiceInfo resources={resources} legekontorurl={legekontorurl} />}</>;
  }

  if (!generalpractitionerResponse.IsDigitalActive) {
    return (
      <>
        <NoServiceInfo resources={resources} legekontorurl={legekontorurl} />
        {children}
      </>
    );
  }

  if (getHasServices(generalpractitionerResponse)) {
    return (
      <div>
        <AnchorLink target="_self" href="/fastlege-tjenester">
          {resources.fastlegeBannerSeDigitaleTjenester}
        </AnchorLink>
      </div>
    );
  } else return <></>;
};

export default DigitaleTjenester;
