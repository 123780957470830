import { GetGeneralPractitionerOperationResponse } from '../../types/entities';

import { TextMessage, EmptyAction } from '@helsenorge/framework-utils/types/entities';

export type REQUEST_GENERAL_PRACTITIONER = 'generalpractitioner/REQUEST_GENERAL_PRACTITIONER';
export const REQUEST_GENERAL_PRACTITIONER: REQUEST_GENERAL_PRACTITIONER = 'generalpractitioner/REQUEST_GENERAL_PRACTITIONER';
export type RECEIVE_GENERAL_PRACTITIONER = 'generalpractitioner/RECEIVE_GENERAL_PRACTITIONER';
export const RECEIVE_GENERAL_PRACTITIONER: RECEIVE_GENERAL_PRACTITIONER = 'generalpractitioner/RECEIVE_GENERAL_PRACTITIONER';
export type FAILURE_GENERAL_PRACTITIONER = 'generalpractitioner/FAILURE_GENERAL_PRACTITIONER';
export const FAILURE_GENERAL_PRACTITIONER: FAILURE_GENERAL_PRACTITIONER = 'generalpractitioner/FAILURE_GENERAL_PRACTITIONER';

export type RequestGeneralPractitionerAction = {
  type: REQUEST_GENERAL_PRACTITIONER;
};

export type ReceiveGeneralPractitionerAction = {
  type: RECEIVE_GENERAL_PRACTITIONER;
  response: GetGeneralPractitionerOperationResponse;
};

export type FailureGeneralPractitionerAction = {
  type: FAILURE_GENERAL_PRACTITIONER;
  error: TextMessage;
};

export type GeneralPractitionerAction =
  | RequestGeneralPractitionerAction
  | ReceiveGeneralPractitionerAction
  | FailureGeneralPractitionerAction
  | EmptyAction;
