import { GetTriageringsoppsettResponse } from '../../types/Helsenorge.Dialog.InternalEntitiesEntities';

import { TextMessage, EmptyAction } from '@helsenorge/framework-utils/types/entities';

export type LOAD = 'triageringsoppsett/LOAD';
export const LOAD = 'triageringsoppsett/LOAD';
export type LOAD_SUCCESS = 'triageringsoppsett/LOAD_SUCCESS';
export const LOAD_SUCCESS = 'triageringsoppsett/LOAD_SUCCESS';
export type LOAD_FAIL = 'triageringsoppsett/LOAD_FAIL';
export const LOAD_FAIL = 'triageringsoppsett/LOAD_FAIL';

export interface LoadAction {
  type: LOAD;
}

export interface LoadSuccessAction {
  type: LOAD_SUCCESS;
  response: GetTriageringsoppsettResponse;
}

export interface LoadFailedAction {
  type: LOAD_FAIL;
  error?: TextMessage;
}

export type TriageringsoppsettAction = LoadAction | LoadSuccessAction | LoadFailedAction | EmptyAction;
