import { GetGeneralPractitionerOperationResponse } from '../../types/entities';

import { TextMessage } from '@helsenorge/framework-utils/types/entities';

import { PartialState } from './state';

export function getGeneralPractitionerError(state: PartialState): TextMessage | undefined {
  return state.generalpractitioner.error;
}

export function getGeneralPractitionerIsFetching(state: PartialState): boolean {
  return state.generalpractitioner.isFetching;
}

export function getGeneralPractitionerResponse(state: PartialState): GetGeneralPractitionerOperationResponse | undefined {
  return state.generalpractitioner.response;
}
