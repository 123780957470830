import { EmptyAction } from '@helsenorge/framework-utils/types/entities';

import {
  REQUEST_GENERAL_PRACTITIONER,
  RECEIVE_GENERAL_PRACTITIONER,
  FAILURE_GENERAL_PRACTITIONER,
  GeneralPractitionerAction,
} from './actions';
import { GeneralPractitionerState, initialState } from './state';

export default function generalpractitioner(
  state: GeneralPractitionerState = initialState,
  action: GeneralPractitionerAction = EmptyAction
): GeneralPractitionerState {
  switch (action.type) {
    case REQUEST_GENERAL_PRACTITIONER:
      return {
        ...state,
        isFetching: true,
      };
    case RECEIVE_GENERAL_PRACTITIONER:
      return {
        ...state,
        isFetching: false,
        response: action.response,
      };
    case FAILURE_GENERAL_PRACTITIONER:
      return {
        ...state,
        isFetching: false,
        error: action.error,
      };
    default:
      return state;
  }
}
