import { forwardRef, ForwardRefRenderFunction, Ref } from 'react';

import Button from '@helsenorge/designsystem-react/components/Button';
import Icon, { SvgIcon } from '@helsenorge/designsystem-react/components/Icon';

export interface Props {
  onClick?: () => void;
  className?: string;
  text: string;
  icon: SvgIcon;
  href?: string;
  fill?: boolean;
}

const TjenesteLink: ForwardRefRenderFunction<HTMLButtonElement, Props> = (
  { onClick, className, icon, text, href, fill },
  ref: Ref<HTMLButtonElement>
) => {
  return (
    <Button
      htmlMarkup={href ? 'a' : 'button'}
      href={href}
      size="large"
      variant={fill ? 'fill' : 'outline'}
      onClick={onClick}
      wrapperClassName={className}
      fluid={true}
      ref={ref}
    >
      <Icon svgIcon={icon} />
      {text}
    </Button>
  );
};

export default forwardRef(TjenesteLink);
