import { useEffect, useState, FC } from 'react';

import { HNDialogMicrowebFastlegepanel } from '../types/Resources';

import Loader from '@helsenorge/designsystem-react/components/Loader';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';

import Languages from '@helsenorge/core-utils/constants/languages';
import { apiGetTidsperiode } from '@helsenorge/dialog-common/store/tidsperiode';
import { isAuthorized } from '@helsenorge/framework-utils/hn-authorize';
import { hasScope, getHarInnsynssperre } from '@helsenorge/framework-utils/hn-user';

import FastlegepanelLinks from './fastlegepanel-links';
import TjenesterButtons from './tjenester-buttons';
import { getUrlFornyResept, getUrlBestillTime, getUrlSkrivMelding } from './utils';
import { HNDialogMicrowebFastlegepanelProject } from '../constants/project-name.constant';
import { getResourceItem } from '../resources';
import {
  getGeneralPractitionerIsFetching,
  getGeneralPractitionerError,
  getGeneralPractitionerResponse,
} from '../store/generalpractitioner/selectors';
import { fetchGeneralPractitioner } from '../store/generalpractitioner/service';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  getTriageringsoppsettLoading,
  getTriageringsoppsettError,
  getTriageringsoppsettResponse,
} from '../store/triageringsoppsett/selectors';
import { fetchTriageringsoppsett } from '../store/triageringsoppsett/service';

import dialogstyles from './styles.module.scss';

const Fastlegepanel: FC = () => {
  const dispatch = useAppDispatch();

  const [urlBestillTime, setUrlBestilltime] = useState<string | undefined>();
  const [urlSkrivMelding, setUrlSkrivMelding] = useState<string | undefined>();
  const [urlFornyResept, setUrlFornyResept] = useState<string | undefined>();

  const generalpractitionerLoading = useAppSelector(getGeneralPractitionerIsFetching);
  const generalpractitionerError = useAppSelector(getGeneralPractitionerError);
  const generalpractitionerResponse = useAppSelector(getGeneralPractitionerResponse);
  const triageringsoppsettResponse = useAppSelector(getTriageringsoppsettResponse);
  const triageringsoppsettLoading = useAppSelector(getTriageringsoppsettLoading);
  const triageringsoppsettError = useAppSelector(getTriageringsoppsettError);
  const loadingTidsperiode = useAppSelector(state => state.tidsperiode.loading);
  const fravaerDatoer = useAppSelector(state => state.tidsperiode.fravaerDatoer);

  const harInnsynssperre = getHarInnsynssperre();
  const harHelsekontakterTilgang = hasScope('helsekontakter');
  const harFastlegeVisningTilgang = hasScope('fastlegevisning');
  const harTimeavtalerTilgang = hasScope('timeavtaler');
  const harMeldingerTilgang = hasScope('meldinger');

  const noAccess = !isAuthorized() || harInnsynssperre || !harFastlegeVisningTilgang;

  const resources = getResourceItem(HNDialogMicrowebFastlegepanelProject, Languages.NORWEGIAN) as HNDialogMicrowebFastlegepanel;

  const setUrls = (): void => {
    setUrlBestilltime(getUrlBestillTime(harTimeavtalerTilgang));
    setUrlSkrivMelding(getUrlSkrivMelding(harMeldingerTilgang));
    setUrlFornyResept(getUrlFornyResept(generalpractitionerResponse, triageringsoppsettResponse, fravaerDatoer));
  };

  useEffect(() => {
    if (!noAccess) {
      dispatch(fetchGeneralPractitioner());
    }
  }, []);

  useEffect(() => {
    if (generalpractitionerResponse) {
      if (generalpractitionerResponse.HerId) {
        dispatch(fetchTriageringsoppsett(generalpractitionerResponse.HerId));
        dispatch(apiGetTidsperiode({ HerId: generalpractitionerResponse.HerId, KunAktive: true }));
      } else {
        setUrls();
      }
    }
  }, [generalpractitionerResponse]);

  useEffect(() => {
    if (triageringsoppsettError) {
      setUrls();
    }
  }, [triageringsoppsettError]);

  useEffect(() => {
    if (triageringsoppsettResponse) {
      setUrls();
    }
  }, [triageringsoppsettResponse]);

  if (noAccess) {
    return null;
  }

  if (generalpractitionerLoading || triageringsoppsettLoading || loadingTidsperiode) {
    return (
      <div className={dialogstyles['fastlegepanel']}>
        <div className="container">
          <div className={dialogstyles['fastlegepanel__wrapper']}>
            <Spacer size="2xl" />
            <Loader center size="large" testId="fastlegepanel-loader" />
            <Spacer size="2xl" />
          </div>
        </div>
      </div>
    );
  }

  if (generalpractitionerError) {
    return (
      <div className={dialogstyles['fastlegepanel']}>
        <div className="container">
          <div className={dialogstyles['fastlegepanel__wrapper']}>
            <NotificationPanel label={generalpractitionerError.Title} variant="error" testId="fastlegepanel-error">
              {generalpractitionerError.Body}
            </NotificationPanel>
          </div>
        </div>
      </div>
    );
  }

  return (
    <>
      {generalpractitionerResponse && (
        <div className={dialogstyles['fastlegepanel']}>
          <div className="container">
            <FastlegepanelLinks
              harHelsekontakterTilgang={harHelsekontakterTilgang}
              generalpractitionerResponse={generalpractitionerResponse}
              fravaerDatoer={fravaerDatoer}
            />
          </div>
        </div>
      )}

      {urlBestillTime && urlSkrivMelding && urlFornyResept && (
        <TjenesterButtons
          resources={resources}
          urlBestillTime={urlBestillTime}
          urlSkrivMelding={urlSkrivMelding}
          urlFornyResept={urlFornyResept}
        />
      )}
    </>
  );
};

export default Fastlegepanel;
