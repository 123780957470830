import { FC } from 'react';

import { HNDialogMicrowebFastlegepanel } from '../types/Resources';

import Button from '@helsenorge/designsystem-react/components/Button';
import Icon from '@helsenorge/designsystem-react/components/Icon';
import ArrowRight from '@helsenorge/designsystem-react/components/Icons/ArrowRight';
import Calendar from '@helsenorge/designsystem-react/components/Icons/Calendar';
import Envelope from '@helsenorge/designsystem-react/components/Icons/Envelope';
import Medicine from '@helsenorge/designsystem-react/components/Icons/Medicine';
import Title from '@helsenorge/designsystem-react/components/Title';

import dialogstyles from './styles.module.scss';

interface Props {
  resources: HNDialogMicrowebFastlegepanel;
  urlBestillTime: string;
  urlSkrivMelding: string;
  urlFornyResept: string;
}

const TjenesterButtons: FC<Props> = ({ resources, urlBestillTime, urlSkrivMelding, urlFornyResept }) => {
  return (
    <div className={dialogstyles['tjenester']}>
      <div className="container">
        <Title className={dialogstyles['tjenester__title']} htmlMarkup="h2" appearance="title3">
          {resources.digitaleTjenesterDineTjenesterTitle}
        </Title>

        <div className={dialogstyles['tjenester__button-wrapper']}>
          <div className={dialogstyles['tjenester__button']}>
            <Button fluid={true} size="large" htmlMarkup="a" href={urlBestillTime}>
              <Icon svgIcon={Calendar} />
              {resources.digitaleTjenesterBestillTimeKnapp}
              <Icon svgIcon={ArrowRight} />
            </Button>
          </div>

          <div className={dialogstyles['tjenester__button']}>
            <Button fluid={true} size="large" htmlMarkup="a" href={urlFornyResept}>
              <Icon svgIcon={Medicine} />
              {resources.digitaleTjenesterFornyReseptKnapp}
              <Icon svgIcon={ArrowRight} />
            </Button>
          </div>

          <div className={dialogstyles['tjenester__button']}>
            <Button fluid={true} size="large" htmlMarkup="a" href={urlSkrivMelding}>
              <Icon svgIcon={Envelope} />
              {resources.digitaleTjenesterSkrivMeldingKnapp}
              <Icon svgIcon={ArrowRight} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TjenesterButtons;
