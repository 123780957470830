import { FC } from 'react';

import { Provider } from 'react-redux';

import { WebcompProps } from '@helsenorge/framework-utils/web-component/register';

import Fastlegepanel from './fastlegepanel';
import { store } from '../store';

const FastlegepanelWithProps: FC<WebcompProps> = (props: WebcompProps) => {
  return (
    <Provider store={store}>
      <Fastlegepanel {...props} />
    </Provider>
  );
};

export default FastlegepanelWithProps;
