import { GetTriageringsoppsettResponse } from '../../types/Helsenorge.Dialog.InternalEntitiesEntities';

import { TextMessage } from '@helsenorge/framework-utils/types/entities';

export interface TriageringsoppsettState {
  loading: boolean;
  error?: TextMessage;
  response?: GetTriageringsoppsettResponse;
}

export interface PartialState {
  triageringsoppsett: TriageringsoppsettState;
}

export const initialState: TriageringsoppsettState = {
  loading: false,
};
