import { FC, JSX } from 'react';

import { FastlegeServices } from '../types/entities';
import { HNDialogMicrowebFastlegepanel } from '../types/Resources';

import Icon from '@helsenorge/designsystem-react/components/Icon';
import Journal from '@helsenorge/designsystem-react/components/Icons/Journal';
import Receptionist from '@helsenorge/designsystem-react/components/Icons/Receptionist';
import LinkList from '@helsenorge/designsystem-react/components/LinkList';
import ListHeader, { ListHeaderText } from '@helsenorge/designsystem-react/components/ListHeader';
import Modal from '@helsenorge/designsystem-react/components/Modal';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';

import { format } from '@helsenorge/core-utils/string-utils';

import { getGeneralPractitionerResponse } from '../store/generalpractitioner/selectors';
import { useAppSelector } from '../store/hooks';
import { getTjenesteUrls } from '../utils/fastlege-utils';

import dialogstyles from './styles.module.scss';

interface Props {
  resources: HNDialogMicrowebFastlegepanel;
  services: FastlegeServices;
  onClose: () => void;
}

export const KontaktLegekontoret: FC<Props> = ({ resources, services, onClose }) => {
  const generalpractitionerResponse = useAppSelector(getGeneralPractitionerResponse);
  const tjenesteUrls = getTjenesteUrls(services.verktoy);

  let linkList: JSX.Element[] = [];

  if (generalpractitionerResponse?.NewContactHash && tjenesteUrls.eKontakt) {
    linkList = linkList.concat(
      <LinkList.Link key={'new-contact'} icon={<Icon svgIcon={Receptionist} />} htmlMarkup="a" href={tjenesteUrls.eKontakt}>
        <ListHeader>
          <ListHeaderText
            firstText={format(resources.kontaktLegekontoretKontakt, [generalpractitionerResponse.BusinessName])}
            firstTextEmphasised
          />
          <ListHeaderText firstText={resources.kontaktLegekontoretFastlegeKontaktBeskrivelse} subText />
        </ListHeader>
      </LinkList.Link>
    );
  }

  if (generalpractitionerResponse?.NewJournalAccessHash) {
    linkList = linkList.concat(
      <LinkList.Link
        key={'innsyn-pasientjournal'}
        icon={<Icon svgIcon={Journal} />}
        htmlMarkup="a"
        href={'/meldinger/innsyn-pasientjournal'}
      >
        <ListHeader>
          <ListHeaderText firstText={resources.kontaktLegekontoretInnsynPasientjournal} firstTextEmphasised />
          <ListHeaderText firstText={resources.kontaktLegekontoretInnsynPasientjournalBeskrivelse} subText />
        </ListHeader>
      </LinkList.Link>
    );
  }

  return generalpractitionerResponse ? (
    <Modal title={resources.kontaktLegekontoretHeader} onClose={onClose} size="large" testId="kontakt-legekontoret-modal">
      {generalpractitionerResponse.Message && (
        <>
          <Spacer size="s" />
          <NotificationPanel label={resources.kontaktLegekontoretFastlegeInfoHeader} variant="info">
            <span className={dialogstyles['kontakt-legekontoret__info-message']}>{generalpractitionerResponse.Message}</span>
          </NotificationPanel>
        </>
      )}

      {linkList.length > 0 && (
        <>
          <Spacer size="s" />
          <LinkList color="neutral" chevron>
            {linkList}
          </LinkList>
        </>
      )}
    </Modal>
  ) : null;
};

export default KontaktLegekontoret;
