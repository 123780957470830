import { useEffect, FC } from 'react';

import { format } from 'date-fns';
import { Provider } from 'react-redux';

import { HNDialogMicrowebFastlegepanel } from '../types/Resources';

import Icon from '@helsenorge/designsystem-react/components/Icon';
import FemaleDoctor from '@helsenorge/designsystem-react/components/Icons/FemaleDoctor';
import MaleDoctor from '@helsenorge/designsystem-react/components/Icons/MaleDoctor';
import Loader from '@helsenorge/designsystem-react/components/Loader';
import NotificationPanel from '@helsenorge/designsystem-react/components/NotificationPanel';
import Spacer from '@helsenorge/designsystem-react/components/Spacer';
import Title from '@helsenorge/designsystem-react/components/Title';

import Languages from '@helsenorge/core-utils/constants/languages';
import { DateFormat, initialize } from '@helsenorge/core-utils/date-fns-utils';
import { WebcompProps } from '@helsenorge/framework-utils/web-component/register';

import Contact from './contact';
import DigitaleTjenester from './digitale-tjenester';
import { HNDialogMicrowebFastlegepanelProject } from '../constants/project-name.constant';
import FastlegeTjenester from '../fastlege-tjenester';
import { getResourceItem } from '../resources';
import { store } from '../store';
import {
  getGeneralPractitionerIsFetching,
  getGeneralPractitionerError,
  getGeneralPractitionerResponse,
} from '../store/generalpractitioner/selectors';
import { fetchGeneralPractitioner } from '../store/generalpractitioner/service';
import { useAppDispatch, useAppSelector } from '../store/hooks';
import {
  getTriageringsoppsettLoading,
  getTriageringsoppsettError,
  getTriageringsoppsettResponse,
} from '../store/triageringsoppsett/selectors';
import { fetchTriageringsoppsett } from '../store/triageringsoppsett/service';
import { mapServices } from '../utils/fastlege-utils';

import dialogstyles from './styles.module.scss';

interface Props {
  fradato?: string | null;
  fastlegekjonn?: string | null;
  fastlegefornavn?: string | null;
  fastlegeetternavn?: string | null;
  legekontornavn?: string | null;
  legekontoradresse?: string | null;
  legekontorpostnr?: string | null;
  legekontorpoststed?: string | null;
  legekontortelefon?: string | null;
  legekontorurl?: string | null;
}

export const Fastlegebanner: FC<Props> = ({
  fradato,
  fastlegekjonn,
  fastlegefornavn,
  fastlegeetternavn,
  legekontornavn,
  legekontoradresse,
  legekontorpostnr,
  legekontorpoststed,
  legekontortelefon,
  legekontorurl,
}) => {
  const dispatch = useAppDispatch();

  const resources: HNDialogMicrowebFastlegepanel = getResourceItem(
    HNDialogMicrowebFastlegepanelProject,
    Languages.NORWEGIAN
  ) as HNDialogMicrowebFastlegepanel;

  const generalpractitionerLoading = useAppSelector(getGeneralPractitionerIsFetching);
  const generalpractitionerError = useAppSelector(getGeneralPractitionerError);
  const generalpractitionerResponse = useAppSelector(getGeneralPractitionerResponse);
  const triageringsoppsettLoading = useAppSelector(getTriageringsoppsettLoading);
  const triageringsoppsettError = useAppSelector(getTriageringsoppsettError);
  const triageringsoppsettResponse = useAppSelector(getTriageringsoppsettResponse);
  const fraDato = !!fradato && format(fradato, DateFormat.LongDate);

  const loading: boolean = generalpractitionerLoading || triageringsoppsettLoading;
  const error = generalpractitionerError || triageringsoppsettError;

  useEffect(() => {
    initialize();
    dispatch(fetchGeneralPractitioner());
  }, []);

  useEffect(() => {
    if (generalpractitionerResponse && generalpractitionerResponse.HerId) {
      dispatch(fetchTriageringsoppsett(generalpractitionerResponse.HerId));
    }
  }, [generalpractitionerResponse]);

  if (loading) {
    return <Loader center size="large" testId="fastlegebanner-loader" />;
  }

  return (
    <>
      <div className={dialogstyles['fastlegebanner']}>
        {fastlegekjonn ? <Icon svgIcon={fastlegekjonn === 'Mann' ? MaleDoctor : FemaleDoctor} size={60} /> : null}

        <div className={dialogstyles['fastlegebanner__fastlegewrapper']}>
          {fastlegefornavn ? (
            <Title htmlMarkup="h2" appearance="title4" className={dialogstyles['fastlegebanner__fastlegename']}>{`${fastlegefornavn}${
              fastlegeetternavn ? ` ${fastlegeetternavn}` : ''
            }`}</Title>
          ) : (
            resources.fastlegeBannerUtenFastlege
          )}

          {fraDato && <div>{`${resources.fastlegeBannerSiden} ${fraDato}`}</div>}

          {generalpractitionerResponse && (
            <Contact
              resources={resources}
              generalpractitionerResponse={generalpractitionerResponse}
              legekontornavn={legekontornavn}
              legekontoradresse={legekontoradresse}
              legekontorpostnr={legekontorpostnr}
              legekontorpoststed={legekontorpoststed}
              legekontortelefon={legekontortelefon}
              legekontorurl={legekontorurl}
            />
          )}

          {error && (
            <>
              <Spacer size="m" />
              <NotificationPanel label={error.Title} variant="error">
                {error.Body}
              </NotificationPanel>
            </>
          )}

          {generalpractitionerResponse && (
            <div className={dialogstyles['fastlegebanner__digitalserviceswrapper']}>
              <DigitaleTjenester
                resources={resources}
                legekontorurl={legekontorurl}
                generalpractitionerResponse={generalpractitionerResponse}
              >
                <FastlegeTjenester
                  services={mapServices(generalpractitionerResponse, triageringsoppsettResponse, true)}
                  resources={resources}
                />
              </DigitaleTjenester>
            </div>
          )}
        </div>
      </div>

      {generalpractitionerResponse && generalpractitionerResponse.OpeningHoursText && (
        <>
          <Spacer size="m" />
          <NotificationPanel label={resources.fastlegeBannerInformasjonLegekontor} variant="info">
            <span className={dialogstyles['fastlegebanner__info-message']}>{generalpractitionerResponse.OpeningHoursText}</span>
          </NotificationPanel>
        </>
      )}
    </>
  );
};

const FastlegebannerWithProps: FC = (props: WebcompProps) => {
  return (
    <Provider store={store}>
      <Fastlegebanner {...props} />
    </Provider>
  );
};

export default FastlegebannerWithProps;
