import { combineReducers } from 'redux';

import tidsperiode from '@helsenorge/dialog-common/store/tidsperiode';
import { resources } from '@helsenorge/framework-utils/resources';

import generalpractitioner from '../store/generalpractitioner/reducer';
import triageringsoppsett from '../store/triageringsoppsett/reducer';

const rootReducer = combineReducers({
  tidsperiode,
  triageringsoppsett,
  generalpractitioner,
  resources,
});

export default rootReducer;
