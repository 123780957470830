import { getTjenesterUrl } from '@helsenorge/framework-utils/hn-proxy-service';

import { VELG_SAMTYKKE_PATH, TRIAGERING_INFORMASJON_PATH } from '../constants/navigation-paths';

// phone numbers might have prefixes like 0047 or +47
export const getPhoneNumberPrefix = (number: string): string => {
  const length: number = number.length;
  if (length <= 8) {
    return '';
  }
  return number.substring(0, length - 8);
};

export const isMobilePhoneNumber = (number: string): boolean => {
  return number.startsWith('9') || number.startsWith('4') || number.startsWith('8');
};

export const isHotlinePhoneNumber = (number: string): boolean => {
  return number.length === 6;
};

export const formatMobileNumber = (number: string): string => {
  const prefix: string = getPhoneNumberPrefix(number);
  const numberWithoutPrefix: string = number.replace(prefix, '');
  const start: string = numberWithoutPrefix.substring(0, 3);
  const middle: string = numberWithoutPrefix.substring(3, 5);
  const end: string = numberWithoutPrefix.substring(5, 8);
  if (prefix.length > 0) {
    return `${prefix} ${start} ${middle} ${end}`;
  }
  return `${start} ${middle} ${end}`;
};

export const formatHotlineNumber = (number: string): string => {
  const start: string = number.substring(0, 3);
  const end: string = number.substring(4, 6);
  return `${start} ${end}`;
};

export const formatNumber = (number: string): string => {
  if (number.indexOf(' ') != -1) {
    number = number.replace(/ /g, '');
  }

  const prefix: string = getPhoneNumberPrefix(number);
  const numberWithoutPrefix: string = number.replace(prefix, '');

  if (isMobilePhoneNumber(numberWithoutPrefix)) {
    return formatMobileNumber(number);
  }

  if (isHotlinePhoneNumber(numberWithoutPrefix)) {
    return formatHotlineNumber(number);
  }

  const start: string = numberWithoutPrefix.substring(0, 2);
  const middle: string = numberWithoutPrefix.substring(2, 4);
  const middle2: string = numberWithoutPrefix.substring(4, 6);
  const end: string = numberWithoutPrefix.substring(6);

  if (prefix.length > 0) {
    return `${prefix} ${start} ${middle} ${middle2} ${end}`;
  }

  return `${start} ${middle} ${middle2} ${end}`;
};

export const trimNumber = (number: string): string => {
  return number
    .replace(/\D/g, '') // Fjerner tekst
    .replace(/\s/g, ''); // Fjerner mellomrom
};

export const getVelgSamtykkeUrl = (): string => {
  return `${getTjenesterUrl()}${VELG_SAMTYKKE_PATH}`;
};

export const getTriageringInformasjonUrl = (kartleggingUrl: string): string => {
  return `${TRIAGERING_INFORMASJON_PATH}?kartleggingUrl=${kartleggingUrl}`;
};

export const getIndividueltTriageringsoppsettHref = (serviceAccess: boolean, kartleggingUrl: string): string => {
  let individueltTriageringsoppsettHref = getVelgSamtykkeUrl();

  if (serviceAccess) {
    individueltTriageringsoppsettHref = getTriageringInformasjonUrl(kartleggingUrl);
  }

  return individueltTriageringsoppsettHref;
};
